















































































































































































































































































































import { ref, defineComponent, onMounted } from "@vue/composition-api";
import { useUserStore } from "@/stores/userStore";
import { Roles } from "@/api/OtiumAppApi";
import { HelpSection } from "@/constants/helpSections";
import { defineStore } from "pinia";
import { use } from "vue/types/umd";
import { useHelpDialogStore } from "@/stores/helpDialogStore";

export default defineComponent({
  name: "OtiumBasePage",
  components: {},
  props: {
    rightSidebarOpened: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root }) {
    const userStore = useUserStore();

    function logOut() {
      // Clear access token
      userStore.logout();

      // Redirect to login page
      root.$router.push({ name: "Home" });
    }

    function goToDashboard() {
      root.$router.push({ name: "Dashboard" });
    }
    function goToTemplates() {
      root.$router.push({ name: "Templates" });
    }

    function goToAdminUsers() {
      root.$router.push({ name: "AdminUsers" });
    }

    function goToOrganization() {
      root.$router.push({ name: "Organization" });
    }

    function goToAccount() {
      root.$router.push({ name: "Account" });
    }

    const profilePicture = ref(null as string | null);
    onMounted(async () => {});

    function openTemplateHelper() {
      const helpDialogStore = useHelpDialogStore();
      helpDialogStore.openDialog(HelpSection.Templates);
    }

    function openProjectHelper() {
      const helpDialogStore = useHelpDialogStore();
      helpDialogStore.openDialog(HelpSection.Projects);
    }

    const drawer = ref(false);

    return {
      userStore,
      logOut,
      goToDashboard,
      goToTemplates,
      goToAdminUsers,
      goToOrganization,
      goToAccount,
      profilePicture,
      Roles,
      openTemplateHelper,
      openProjectHelper,
      drawer
    };
  }
});
